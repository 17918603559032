import React from "react"

import api from "../../api/index"
import styles from "../../styles/Additional.module.scss"

import { Input, Tag, List, message } from "antd"

const SetProductIssue = ({ productID, issues, getAdditionalInfo }) => {
  const registerProductIssue = (value, event) => {
    if (!value.length) {
      message.warning("값을 입력해주세요.")
      return
    }

    if (!productID) {
      message.warning("제품을 검색해주세요.")
      return
    }

    api.registerProductIssue(productID, value).then(() => {
      message.success("등록되었습니다.")
      getAdditionalInfo(productID)
    })
  }

  const deleteProductIssue = issueID => {
    api.deleteProductIssue(issueID).then(() => {
      message.success("삭제되었습니다.")
      getAdditionalInfo(productID)
    })
  }

  return (
    <div>
      <List
        size="large"
        header={<h5>제품 이슈</h5>}
        bordered
        dataSource={issues}
        renderItem={item => (
          <List.Item>
            {item.issueText}
            <span className={styles.tag_wrapper}>
              <Tag color="volcano" className={styles.tagclass} onClick={() => deleteProductIssue(item.issueID)}>
                삭제
              </Tag>
            </span>
          </List.Item>
        )}
      />

      <br />
      <Input.Search placeholder="이슈 입력" enterButton="이슈 추가" size="default" onSearch={(value, event) => registerProductIssue(value, event)} />

      {/* {tags.map((item, index) => (
        <Tag color="volcano" key={index} closable className={styles.tagclass}>
          {item.tagName}
        </Tag>
      ))} */}
    </div>
  )
}

export default SetProductIssue
