import s3 from "../config/aws"
import { message } from "antd"

export const uploadToS3 = (fileObj, productID, fileName) => {
  return new Promise((resolve, reject) => {
    if (!fileObj) {
      reject(alert("파일이 선택되지 않았습니다."))
    }

    var albumPhotosKey = "app/product/" + encodeURIComponent(String(productID).padStart(6, "0")) + "/"

    var photoKey = albumPhotosKey + fileName

    s3.upload(
      {
        Key: photoKey,
        Body: fileObj,
        ACL: "public-read",
        ContentType: "image/png",
      },
      (err, data) => {
        if (err) {
          reject("There was an error uploading photo: ", err.message)
        }
        resolve(data)
      }
    )
  })
}

export const deleteFromS3 = productID => {
  return new Promise((resolve, reject) => {
    var productIDFolder = "app/product/" + encodeURIComponent(productID) + "/"

    s3.listObjects({ Prefix: productIDFolder }, (err, data) => {
      if (err) {
        message.warning("파일 삭제 Listing 오류: ", err.message)
        reject(err)
      }

      var objects = data.Contents.map(object => ({ Key: object.Key }))
      s3.deleteObjects(
        {
          Delete: { Objects: objects, Quiet: true },
        },
        (err, data) => {
          if (err) {
            message.warning("S3 파일 삭제 오류: ", err.message)
            reject()
          }

          if (data) {
            resolve(data)
          }
        }
      )
    })
  })
}
