/* 제품 기본 정보 항목들 */
export const productInfoForm = {
  productID: "",
  temporaryName: "",
  manufacturer: "",
  distributor: "",
  brandLine: "",
  series: "",
  nameKor: "",
  nameEtc: "",
  price: "",
  unavailable: 0,
  type: "",
  flavor: "",
  basicIngredient: "",
  additionalIngredient: "",
  weight: "",
  calorie: "",
  barcode: "",
  memo: "",
  image_front: "",
  image_front_300: "",
  marketingPhrase: "",
  promotionStartingDate: "",
  promotionEndingDate: "",
}

/* 제품 유형 List */

export const productType = {
  과자: "snack",
  초콜릿: "chocolate",
  음료: "drink",
  아이스크림: "icecream",
  사탕: "candy",
  젤리: "jelly",
  껌: "gum",
  빵: "bread",
  기타: "others",
}
