import React, { Component } from "react"
import { Input, PageHeader, Col, Row, message } from "antd"

import api from "../../api/index"

import styles from "../../styles/Additional.module.scss"
import SetProductTag from "./SetProductTag"
import SetProductIssue from "./SetProductIssue"
import ProductInfoDisplay from "./ProductInfoDisplay"
import SetProductDiscountTag from "./SetProductDiscountTag"

export class AdditionalInfo extends Component {
  state = {
    productID: "",
    issues: [],
    tags: [],
    discountTags: [],
  }

  getAdditionalInfo = productID => {
    this.setState({ productID: productID })

    api
      .getProductInfo(productID)
      .then(res => {
        this.setState({ productInfo: res.data[0] })
      })
      .catch(() => {
        message.error("제품을 찾을 수 없습니다.")
        return
      })

    api.getProductAdditionalInfo(productID).then(res => {
      this.setState({
        issues: res.data.productIssue,
        tags: res.data.productTag,
        discountTags: res.data.productDiscountTag,
      })
    })
  }

  render() {
    const { productID, issues, tags, discountTags, productInfo } = this.state

    return (
      <>
        <PageHeader className="site-page-header" backIcon={false} title="추가 정보 등록" subTitle="상품 이슈/태그/할인 태그 추가 및 삭제" />

        <div className={styles.search_bar}>
          <Input.Search danger color="volcano" placeholder="상품 번호 입력" enterButton="상품 검색" size="large" onSearch={value => this.getAdditionalInfo(value)} />
        </div>

        <ProductInfoDisplay productInfo={productInfo} />

        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <SetProductIssue productID={productID} issues={issues} getAdditionalInfo={this.getAdditionalInfo} />
          </Col>
          <Col span={8}>
            <SetProductTag productID={productID} tags={tags} />
          </Col>
          <Col span={8}>
            <SetProductDiscountTag productID={productID} discountTags={discountTags} />
          </Col>
        </Row>
      </>
    )
  }
}

export default AdditionalInfo
