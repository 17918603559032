export const dataURLToBlob = function(dataURL) {
  var BASE64_MARKER = ";base64,"
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    var parts = dataURL.split(",")
    var contentType = parts[0].split(":")[1]
    var raw = parts[1]

    return new Blob([raw], { type: contentType })
  }

  var parts = dataURL.split(BASE64_MARKER)
  var contentType = parts[0].split(":")[1]
  var raw = window.atob(parts[1])
  var rawLength = raw.length

  var uInt8Array = new Uint8Array(rawLength)

  for (var i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }

  return new Blob([uInt8Array], { type: contentType })
}

export const resizeImage = (inputFile, size) => {
  return new Promise(resolve => {
    let resizedImage = ""
    // var file = e.target.files[0]
    // Ensure it's an image
    if (inputFile.type.match(/image.*/)) {
      // Load the image
      var reader = new FileReader()
      reader.onload = function(readerEvent) {
        var image = new Image()
        image.onload = function(imageEvent) {
          image.crossOrigin = "anonymous"

          // Resize the image
          var canvas = document.getElementById("canvas"),
            max_size = size, // TODO : pull max size from a site config
            width = image.width,
            height = image.height

          canvas.crossOrigin = "anonymous"

          let originalWidth = image.width
          let originalHeight = image.height

          if (width > height) {
            if (width > max_size) {
              height *= max_size / width
              width = max_size
            }
          } else {
            if (height > max_size) {
              width *= max_size / height
              height = max_size
            }
          }
          canvas.width = width
          canvas.height = height

          var ctx = canvas.getContext("2d")
          ctx.imageSmoothingQuality = "high"
          ctx.drawImage(image, 0, 0, width, height)
          var dataUrl = canvas.toDataURL("image/png", 1.0)
          resizedImage = dataURLToBlob(dataUrl)

          resolve([resizedImage, originalWidth, originalHeight])
        }
        image.src = readerEvent.target.result
      }
      reader.readAsDataURL(inputFile)
    }
  })
}

export const resizeImageByUrl = (url, size) => {
  return new Promise(resolve => {
    let resizedImage = ""

    var image = new Image()
    image.src = url
    image.setAttribute("crossorigin", "anonymous")
    image.onload = function(imageEvent) {
      // Resize the image
      var canvas = document.getElementById("canvas"),
        max_size = size, // TODO : pull max size from a site config
        width = image.width,
        height = image.height

      let originalWidth = image.width
      let originalHeight = image.height

      if (width > height) {
        if (width > max_size) {
          height *= max_size / width
          width = max_size
        }
      } else {
        if (height > max_size) {
          width *= max_size / height
          height = max_size
        }
      }
      canvas.width = width
      canvas.height = height

      var ctx = canvas.getContext("2d")
      ctx.imageSmoothingQuality = "high"
      ctx.drawImage(image, 0, 0, width, height)
      var dataUrl = canvas.toDataURL("image/png", 1.0)
      resizedImage = dataURLToBlob(dataUrl)

      resolve([resizedImage, originalWidth, originalHeight])
    }
  })
}
