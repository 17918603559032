import React, { useState } from "react"

import { productInfoForm } from "../../constants/productInfo"
import { parsePastedValueGoogleSheet } from "../../util/index"

const ManualInput = (props) => {
  const [imageUrl, setImageUrl] = useState("")

  const handlePaste = (e) => {
    const defaultState = { ...productInfoForm }

    const result = parsePastedValueGoogleSheet(e.clipboardData.getData("Text"))
    props.setStateOutside({ productInfo: { ...defaultState, ...result } })
  }

  return (
    <div style={styles.wrapper}>
      <span style={{ fontSize: 18, marginBottom: 10 }}>붙여넣기 - 구글 스프레드시트</span>
      <textarea id="manual" rows="3" style={{ width: "100%", resize: "none" }} onPaste={handlePaste}></textarea>
      <br />
    </div>
  )
}

const styles = {
  wrapper: {
    marginTop: 50,
    padding: 20,
    border: "1px dotted rgba(100,100,100,0.6)",
  },

  hr: {
    height: "1px",
    backgroundColor: "#FFC107",
    margin: "40px 0",
  },
}

export default ManualInput
