import React, { Component } from "react"

import styles from "../../styles/Main.module.scss"

import { Link } from "react-router-dom"
import { Menu } from "antd"

export class SideMenu extends Component {
  state = {
    current: "1",
  }

  handleClick = e => {
    this.setState({
      current: e.key,
    })
  }

  render() {
    return (
      <div>
        <div className={styles.sidemenuLogo}>ADMIN</div>
        <Menu
          theme="light"
          onClick={this.handleClick}
          className={styles.sidemenu}
          selectedKeys={[this.state.current]}
          defaultOpenKeys={["sub1"]}
          mode="inline"
        >
          <Menu.Item key="1">
            <Link to="/">상품 정보 등록</Link>
          </Menu.Item>
          <Menu.Item key="2">
            <Link to="/additional">상품 추가 정보 등록</Link>
          </Menu.Item>
          <Menu.Item key="3">
            <Link to="/tagManagement">태그 관리</Link>
          </Menu.Item>
          <Menu.Item key="4">
            <Link to="/search">등록 제품 검색</Link>
          </Menu.Item>
        </Menu>
      </div>
    )
  }
}

export default SideMenu
