import React, { Component } from "react"

export class ImageResize extends Component {
  render() {
    return (
      <div style={styles.wrapper}>
        <h4>이미지 미리보기</h4>
        [선택된 이미지 : {`${this.props.naturalWidth} × ${this.props.naturalHeight}`}]
        <br />
        <br />
        <img id="temp_img" src="" style={{ maxWidth: "500px", maxHeight: "500px", verticalAlign: "text-top" }} alt="" crossOrigin="anonymous" />
        <canvas id="canvas" style={{ maxWidth: "500px", maxHeight: "500px", verticalAlign: "text-top" }} crossOrigin="anonymous" />
        <br />
        {/* <Button variant="warning" onClick={this.convertImage}>
          변환
        </Button> */}
      </div>
    )
  }
}

const styles = {
  wrapper: {
    display: "inline-block",
    float: "left",
    width: "50%",
    marginTop: 50,
    marginRight: 30,
    padding: 20,
    border: "1px dotted rgba(100,100,100,0.6)",
  },
}
export default ImageResize
