import axios from "axios"

const axiosInstance = axios.create({
  withCredentials: true,
})

const baseAPI = {
  get: (URL, data) => {
    return axiosInstance.get(URL, data)
  },
  put: (URL, data) => {
    return axiosInstance.put(URL, data)
  },
  post: (URL, data) => {
    return axiosInstance.post(URL, data)
  },
  delete: (URL, data) => {
    return axiosInstance.delete(URL, data)
  },
}

export default baseAPI
