import React from "react"

import { PageHeader, Row, Col } from "antd"

import styles from "../../styles/TagManagement.module.scss"
import TagList from "./TagList"
import DiscountTagList from "./DiscountTagList"

const TagManagement = () => {
  return (
    <>
      <header className={styles.header}>
        <PageHeader className="site-page-header" backIcon={false} title="태그 관리" subTitle="일반 태그 및 할인 정보 태그 리스트 관리" />
      </header>

      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 36 }} style={{ margin: "0 10px" }}>
        <Col span={12}>
          <TagList />
        </Col>
        <Col span={12}>
          <DiscountTagList />
        </Col>
      </Row>
    </>
  )
}

export default TagManagement
