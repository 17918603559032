import React, { Component } from "react"

import api from "../../api/index"

import styles from "../../styles/Main.module.scss"

import { Button as AButton } from "antd"
import { PageHeader, message } from "antd"

import { productInfoForm } from "../../constants/productInfo"
import { uploadToS3 } from "../../util/aws"
import { resizeImage } from "../../util/image"
import { dragAndDropHandler } from "../../util/dragFile"

import ImageResize from "./ImageResize"
import PasteInput from "./PasteInput"
import PasteInputShort from "./PasteInputShort"
import PasteInputGoogleSheet from "./PasteInputGoogleSheet"
import DBTable from "./DBTable"
// import ShoppingPreview from "./ShoppingPreview"

export class AppendToDB extends Component {
  state = {
    header: [],
    results: [],
    entireProductCount: 0,
    pageCount: 0,
    showDragUI: false,

    productInfo: { ...productInfoForm },
    naturalWidth: "",
    naturalHeight: "",
    resizedThumbImage: "",
  }

  image_front_Ref = React.createRef()

  setStateOutside = result => {
    this.setState({ ...result })
  }

  handleValueChange = (event, fieldName) => {
    this.setState({
      productInfo: { ...this.state.productInfo, [fieldName]: event.target.value },
    })
  }

  handleValueCheck = e => {
    this.setState({
      productInfo: {
        ...this.state.productInfo,
        unavailable: e.target.checked,
      },
    })
  }

  handleFileChange = e => {
    inputFile = e.target.files[0]
    this.handleResizeImage(inputFile)
  }

  handleFileDrop = e => {
    e.stopPropagation()
    e.preventDefault()

    if (e.dataTransfer.files.length > 1) {
      message.warning("한 개의 파일만 선택해주세요.")
      return
    }

    inputFile = e.dataTransfer.files[0]

    if (inputFile) {
      this.handleResizeImage(inputFile)
    }
  }

  handleResizeImage = inputFile => {
    resizeImage(inputFile, 450).then(result => {
      this.setState({
        resizedThumbImage: result[0],
        naturalWidth: result[1],
        naturalHeight: result[2],
      })
    })

    resizeImage(inputFile, 1200).then(result => {
      this.setState({
        resizedImage: result[0],
      })
    })
  }

  register = async () => {
    const { productInfo, resizedImage, resizedThumbImage } = this.state

    if (!inputFile && !productInfo.image_front) {
      message.warning("사진이 선택되지 않았습니다.")
      return
    }

    if (!window.confirm("등록하시겠습니까?")) return

    let imageFrontUrl = ""
    let imageFrontThumbUrl = ""

    api
      .registerProduct(productInfo)
      .then(res => {
        const { insertId } = res.data

        Promise.all([
          uploadToS3(resizedImage, insertId, "image_front.png"),
          uploadToS3(resizedThumbImage, insertId, "image_front_thumb.png"),
        ])
          .then(res => {
            imageFrontUrl = res[0].Location
            imageFrontThumbUrl = res[1].Location

            api
              .registerProductImage(insertId, imageFrontUrl, imageFrontThumbUrl)
              .then(() => message.success("등록 성공"))
              .catch(() => message.warning("등록 실패"))
          })
          .catch(() => {
            message.warning("사진 등록에 실패했습니다.")
          })
      })
      .finally(() => {
        this.clearEntireInputs()
      })
  }

  clearEntireInputs = () => {
    const inputs = document.getElementsByTagName("input")
    const textareas = document.getElementsByTagName("textarea")

    var inputs_new = Array.from(inputs)
    var textareas_new = Array.from(textareas)

    inputs_new.forEach(element => {
      element.value = ""
    })
    textareas_new.forEach(element => {
      element.value = ""
    })
  }

  componentDidMount() {
    dragAndDropHandler()
    document.addEventListener("drop", this.handleFileDrop, false)
  }

  render() {
    const { productInfo } = this.state

    return (
      <>
        <PageHeader
          className="site-page-header"
          backIcon={false}
          title="상품 정보 등록"
          subTitle="상품 정보 등록/수정/삭제"
        />
        <div style={{ textAlign: "center", marginBottom: "20px" }}>
          <a href="http://dev.snackpot.kr/search" rel="noopener noreferrer" target="_blank">
            <AButton type="primary" style={{ marginRight: "10px" }}>
              검색 테스트
            </AButton>
          </a>
          <a href="https://trello.com/b/LlTyTMjh/snackpotapp" rel="noopener noreferrer" target="_blank">
            <AButton type="primary">트렐로</AButton>
          </a>
        </div>
        <DBTable />
        <form name="form">
          <div className={styles.wrapper}>
            <table className={styles.inputs_table}>
              <tbody>
                <tr>
                  <td title="임시 이름">
                    <div>임시 이름</div>
                    <input
                      type="text"
                      value={productInfo.temporaryName}
                      onChange={e => this.handleValueChange(e, "temporaryName")}
                    />
                  </td>
                  <td title="제품의 제조사. ex)롯데">
                    <div>제조사/프랜차이즈 이름</div>
                    <input
                      type="text"
                      value={productInfo.manufacturer}
                      onChange={e => this.handleValueChange(e, "manufacturer")}
                    />
                  </td>
                  <td title="제품의 유통처. 특정한 유통처에서 구입할 수 있는 경우. ex)GS25, CU, 세븐일레븐">
                    <div>유통처</div>
                    <input
                      type="text"
                      value={productInfo.distributor}
                      onChange={e => this.handleValueChange(e, "distributor")}
                    />
                  </td>
                  <td title="제품의 브랜드 라인. 예시)노브랜드 or 닥터유 등">
                    <div>브랜드 라인</div>
                    <input
                      type="text"
                      value={productInfo.brandLine}
                      onChange={e => this.handleValueChange(e, "brandLine")}
                    />
                  </td>
                  <td title="제품의 시리즈. 예시)허니버터칩">
                    <div>시리즈</div>
                    <input type="text" value={productInfo.series} onChange={e => this.handleValueChange(e, "series")} />
                  </td>
                  <td>
                    <div>기본 이름</div>
                    <input
                      type="text"
                      value={productInfo.nameKor}
                      onChange={e => this.handleValueChange(e, "nameKor")}
                    />
                  </td>
                  <td title="제품 이름을 다르게 표기하는 경우">
                    <div>추가 이름</div>
                    <input
                      type="text"
                      value={productInfo.nameEtc}
                      onChange={e => this.handleValueChange(e, "nameEtc")}
                    />
                  </td>
                </tr>

                <tr>
                  <td title="과자 이름에 들어있는 맛 이름. ex)'허니버터칩 슈크림메이플맛'인 경우 '슈크림메이플맛'">
                    <div>맛 이름</div>
                    <input type="text" value={productInfo.flavor} onChange={e => this.handleValueChange(e, "flavor")} />
                  </td>
                  <td>
                    <div>가격</div>
                    <input type="text" value={productInfo.price} onChange={e => this.handleValueChange(e, "price")} />
                  </td>
                  <td>
                    <div>출시일/프로모션 시작일</div>
                    <input
                      type="date"
                      value={productInfo.promotionStartingDate}
                      onChange={e => this.handleValueChange(e, "promotionStartingDate")}
                    />
                  </td>
                  <td>
                    <div>단종일/프로모션 종료일</div>
                    <input
                      type="date"
                      max="2999-12-31"
                      value={productInfo.promotionEndingDate}
                      onChange={e => this.handleValueChange(e, "promotionEndingDate")}
                    />
                  </td>
                  <td title="단종 여부. 체크 되어있으면 단종 / 체크 되어 있지 않으면 판매 중">
                    <div>단종 여부</div>
                    <input type="checkbox" checked={productInfo.unavailable} onChange={e => this.handleValueCheck(e)} />
                  </td>
                </tr>
                <tr>
                  <td title="제품 유형">
                    <div>제품 유형</div>
                    <select value={productInfo.type} onChange={e => this.handleValueChange(e, "type")}>
                      <option value=""></option>
                      <option value="snack">과자</option>
                      <option value="chocolate">초콜릿</option>
                      <option value="drink">음료</option>
                      <option value="icecream">아이스크림</option>
                      <option value="candy">사탕</option>
                      <option value="jelly">젤리</option>
                      <option value="gum">껌</option>
                      <option value="bread">빵</option>
                      <option value="others">기타</option>
                    </select>
                  </td>

                  <td>
                    <div title="제품 주요 성분. ex)'꼬깔콘 새우마요맛'인 경우 '새우'">제품 주요 성분</div>
                    <input
                      type="text"
                      value={productInfo.basicIngredient}
                      onChange={e => this.handleValueChange(e, "basicIngredient")}
                    />
                  </td>
                  <td>
                    <div>제품 추가 성분</div>
                    <input
                      type="text"
                      value={productInfo.additionalIngredient}
                      onChange={e => this.handleValueChange(e, "additionalIngredient")}
                    />
                  </td>
                  <td>
                    <div>무게/용량</div>
                    <input type="text" value={productInfo.weight} onChange={e => this.handleValueChange(e, "weight")} />
                  </td>
                  <td>
                    <div>칼로리</div>
                    <input
                      type="text"
                      value={productInfo.calorie}
                      onChange={e => this.handleValueChange(e, "calorie")}
                    />
                  </td>
                  <td>
                    <div>바코드 번호</div>
                    <input
                      type="text"
                      value={productInfo.barcode}
                      onChange={e => this.handleValueChange(e, "barcode")}
                    />
                  </td>
                  <td>
                    <div>마케팅 문구</div>
                    <input
                      type="text"
                      value={productInfo.marketingPhrase}
                      onChange={e => this.handleValueChange(e, "marketingPhrase")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <div>메모</div>
                    <input
                      type="text"
                      style={{ width: "100%" }}
                      value={productInfo.memo}
                      onChange={e => this.handleValueChange(e, "memo")}
                    />
                  </td>

                  <td colSpan="2">
                    <div>이미지 파일 선택</div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={this.image_front_Ref}
                      id="imageFile"
                      onChange={this.handleFileChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div style={{ textAlign: "center" }}>
              <AButton type="primary" onClick={this.register} style={{ marginRight: 10 }}>
                등록하기
              </AButton>
              <AButton onClick={this.clearEntireInputs} style={{ margin: "auto" }}>
                모두 지우기
              </AButton>
            </div>
          </div>
        </form>
        <PasteInput setStateOutside={this.setStateOutside} clearEntireInputs={this.clearEntireInputs} />
        <PasteInputShort setStateOutside={this.setStateOutside} clearEntireInputs={this.clearEntireInputs} />
        <PasteInputGoogleSheet setStateOutside={this.setStateOutside} clearEntireInputs={this.clearEntireInputs} />
        <ImageResize naturalWidth={this.state.naturalWidth} naturalHeight={this.state.naturalHeight} />
        {/* <ShoppingPreview keyword={this.state.productInfo.previewKeyword} /> */}
      </>
    )
  }
}

let inputFile = undefined

export default AppendToDB
