import React, { useState } from "react"
import { Input, Table, PageHeader, message, Pagination } from "antd"

import styles from "../../styles/Search.module.scss"
import { deleteFromS3 } from "../../util/aws"
import api from "../../api/index"

import Modify from "../AppendToDB/Modify"

const Search = () => {
  const [keyword, setKeyword] = useState("")
  const [resultCount, setResultCount] = useState("")
  const [result, setResult] = useState([])
  const [column, setColumn] = useState([])
  const [pagination, setPagination] = useState({
    maxDisplay: 0,
    displayAtOnePage: 15,
    pageCount: 0,
  })
  const [showModal, setShowModal] = useState(false)
  const [modifyingProductInfo, setModifyingProductInfo] = useState({})

  const executeSearch = (value, offset = 0, limit = 15) => {
    api
      .searchProduct(value, { offset, limit })
      .then(res => {
        const column = []
        const tempResult = res.data["result"]

        column.push({
          ellipsis: true,
          title: "데이터 관리",
          dataIndex: "deleteItem",
          key: "deleteItem",
        })

        Object.keys(tempResult[0]).forEach(item => {
          column.push({
            ellipsis: true,
            title: item,
            dataIndex: item,
            key: item,
          })
        })

        tempResult.forEach(resultItem => {
          resultItem["deleteItem"] = (
            <>
              <span
                onClick={() => {
                  deleteProduct(resultItem.productID)
                }}
                style={{ marginRight: 10 }}
              >
                삭제
              </span>
              <span
                onClick={() => {
                  setShowModal(true)
                  setModifyingProductInfo(resultItem)
                }}
              >
                수정
              </span>
            </>
          )

          resultItem["image_front"] = (
            <a target="_blank" href={resultItem["image_front"]} rel="noopener noreferrer">
              {resultItem["image_front"]}
            </a>
          )

          resultItem["image_front_300"] = (
            <a target="_blank" href={resultItem["image_front_300"]} rel="noopener noreferrer">
              {resultItem["image_front_300"]}
            </a>
          )
        })

        setKeyword(value)
        setResultCount(res.data["resultCount"])
        setResult(res.data["result"])
        setColumn(column)
        setPagination({
          ...pagination,
          pageCount: Math.ceil(res.data["resultCount"] / pagination.displayAtOnePage),
        })
      })
      .catch(err => {
        if (err.response.status === 404) message.error("결과를 찾을 수 없습니다.")
        else message.error("오류가 발생했습니다.")

        setResult([])
      })
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const deleteProduct = async productID => {
    const response = window.confirm("정말 삭제하시겠습니까?")

    if (!response) return

    api
      .deleteProduct(productID)
      .then(() => deleteFromS3(productID))
      .then(() => {
        message.success("삭제 성공")
        executeSearch(keyword)
      })
      .catch(() => message.warning("삭제 실패"))
  }

  return (
    <>
      <PageHeader className="site-page-header" backIcon={false} title="등록 제품 검색" subTitle="등록된 제품 검색" />

      <div className={styles.input}>
        <Input.Search
          id="searchBtn"
          placeholder="검색어 입력"
          enterButton="검색"
          size="default"
          onSearch={value => executeSearch(value)}
        />
      </div>

      {resultCount > 0 && <div className={styles.resultCount}>총 {resultCount}개의 결과가 있습니다. </div>}

      {resultCount > 0 && (
        <>
          <Table
            rowKey="productID"
            pagination={false}
            bordered={true}
            size="small"
            columns={column}
            dataSource={result}
            scroll={{ x: true }}
          />
          <Pagination
            defaultCurrent={1}
            defaultPageSize={pagination.displayAtOnePage}
            total={resultCount}
            size="large"
            onChange={(page, pageSize) => {
              executeSearch(keyword, (page - 1) * pagination.displayAtOnePage, pagination.displayAtOnePage)
            }}
            className={styles.pagination_wrapper}
          />
        </>
      )}

      {showModal && <Modify closeModal={closeModal} productInfo={modifyingProductInfo} />}
    </>
  )
}

export default Search
