import React, { useState } from "react"

import styles from "../../styles/Additional.module.scss"
import { Select, message, Tag, List, Button } from "antd"

import api from "../../api/index"

const SetProductDiscountTag = ({ productID, discountTags }) => {
  const [discountTagList, setDiscountTagList] = useState([])
  const [selectedDiscountTag, setSelectedDiscountTag] = useState()

  const getDiscountTagList = open => {
    if (open)
      api.getDiscountTagList().then(res => {
        setDiscountTagList(res.data)
      })
  }

  const handleDiscountTagSelect = value => {
    setSelectedDiscountTag(value.key)
  }

  const handleRegisterDiscountTag = () => {
    if (productID && selectedDiscountTag) {
      api
        .registerDiscountTagToProduct(productID, selectedDiscountTag)
        .then(() => message.success("등록에 성공했습니다."))
        .catch(() => message.warning("등록에 실패했습니다."))
    } else {
      message.warning("제품 정보나 태그를 불러올 수 없습니다.")
    }
  }

  return (
    <div className={styles.SetProductTag}>
      <List
        size="large"
        header={<h5>제품 할인 태그</h5>}
        bordered
        dataSource={discountTags}
        renderItem={item => (
          <List.Item>
            {item.tagName}
            <span className={styles.tag_wrapper}>
              <Tag color="volcano" className={styles.tagclass}>
                삭제
              </Tag>
            </span>
          </List.Item>
        )}
      />

      <br />
      <Select
        labelInValue
        defaultActiveFirstOption={false}
        value={selectedDiscountTag}
        className={styles.selector}
        onChange={handleDiscountTagSelect}
        onDropdownVisibleChange={getDiscountTagList}
      >
        {discountTagList.map((item, index) => (
          <Select.Option value={item.tagID} key={index}>
            <Tag color="volcano">{item.tagName}</Tag>
          </Select.Option>
        ))}
      </Select>

      <Button type="primary" onClick={handleRegisterDiscountTag}>
        태그 등록
      </Button>
      {/* <br />
      <Input.Search placeholder="태그 입력" enterButton="태그 추가" size="default" onSearch={value => this.getAdditionalInfo(value)} /> */}
    </div>
  )
}

export default SetProductDiscountTag
