import React, { useState } from "react"

import { productInfoForm } from "../../constants/productInfo"
import { parsePastedValue } from "../../util/index"
import { resizeImageByUrl } from "../../util/image"

const ManualInput = (props) => {
  const [imageUrl, setImageUrl] = useState("")

  const handlePaste = (e) => {
    const defaultState = { ...productInfoForm }

    const result = parsePastedValue(e.clipboardData.getData("Text"))
    props.setStateOutside({ productInfo: { ...defaultState, ...result } })

    setImageUrl(result.image_front)

    resizeImageByUrl(result.image_front, 450).then((result) => {
      props.setStateOutside({
        resizedThumbImage: result[0],
        naturalWidth: result[1],
        naturalHeight: result[2],
      })
    })

    resizeImageByUrl(result.image_front, 1200).then((result) => {
      props.setStateOutside({
        resizedImage: result[0],
      })
    })
  }

  return (
    <div style={styles.wrapper}>
      {/* <div style={styles.hr}></div> */}
      <span style={{ fontSize: 18, marginBottom: 10 }}>붙여넣기 - 상공회의소 데이터</span>
      <textarea id="manual" rows="6" style={{ width: "100%", resize: "none" }} onPaste={handlePaste}></textarea>
      <br />
      <a target="_blank" rel="noopener noreferrer" href={imageUrl}>
        {imageUrl}
      </a>
      {/* <div style={styles.hr}></div> */}
    </div>
  )
}

const styles = {
  wrapper: {
    marginTop: 50,
    padding: 20,
    border: "1px dotted rgba(100,100,100,0.6)",
  },

  hr: {
    height: "1px",
    backgroundColor: "#FFC107",
    margin: "40px 0",
  },
}

export default ManualInput
