// import URL from "./url"

const URL_PROD = "https://api.snackpot.kr"
const URL_DEV = "http://localhost:6495"
const baseURL = process.env.REACT_APP_ENV === "prod" ? URL_PROD : URL_DEV

export const apiURL = {
  GET_ENTIRE_LIST: (startNum, numOfItem) => `${baseURL}/admin/entireList?startNum=${startNum}&numOfItem=${numOfItem}`,
  REGISTER_PRODUCT: `${baseURL}/admin/product`,
  SEARCH_PRODUCT: keyword => `${baseURL}/search/${keyword}`,
  DELETE_PRODUCT: productID => `${baseURL}/admin/product/${productID}`,
  REGISTER_PRODUCT_IMAGE: productID => `${baseURL}/admin/product/image/${productID}`,
  MODIFY_PRODUCT: productID => `${baseURL}/admin/product/${productID}`,
  GET_PRODUCT_INFO: productID => `${baseURL}/admin/product/${productID}`,
  GET_PRODUCT_ADDITIONAL_INFO: productID => `${baseURL}/admin/product/additionalInfo/${productID}`,

  GET_PRODUCT_ISSUE: productID => `${baseURL}/admin/product/issue/${productID}`,
  REGISTER_PRODUCT_ISSUE: productID => `${baseURL}/admin/product/issue/${productID}`,
  DELETE_PRODUCT_ISSUE: issueID => `${baseURL}/admin/product/issue/${issueID}`,

  GET_PRODUCT_TAG: productID => `${baseURL}/admin/product/tag/${productID}`,
  GET_PRODUCT_DISCOUNT_TAG: productID => `${baseURL}/admin/product/discountTag/${productID}`,

  GET_TAG_LIST: `${baseURL}/admin/tag`,
  GET_DISCOUNT_TAG_LIST: `${baseURL}/admin/discountTag`,

  REGISTER_TAG: `${baseURL}/admin/tag`,
  REGISTER_DISCOUNT_TAG: `${baseURL}/admin/discountTag`,

  REGISTER_TAG_TO_PRODUCT: productID => `${baseURL}/admin/product/tag/${productID}`,
  REGISTER_DISCOUNT_TAG_TO_PRODUCT: productID => `${baseURL}/admin/product/discountTag/${productID}`,
}
