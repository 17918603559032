import React, { Component } from "react"

import { Button as AButton, message } from "antd"

import { productInfoForm } from "../../constants/productInfo"

import styles from "../../styles/Main.module.scss"
import api from "../../api/index"

export class Modify extends Component {
  state = {
    productInfo: {
      ...productInfoForm,
      ...this.props.productInfo,
    },
  }

  handleValueChange = (event, fieldName) => {
    this.setState({
      productInfo: { ...this.state.productInfo, [fieldName]: event.target.value },
    })
  }

  handleValueCheck = e => {
    this.setState({
      productInfo: {
        ...this.state.productInfo,
        unavailable: e.target.checked ? 1 : 0,
      },
    })
  }

  modify = (productID, productInfo) => {
    api
      .modifyProduct(productID, productInfo)
      .then(() => {
        message.success("수정 완료")
        document.getElementById("loadList").click()
        this.props.closeModal()
      })
      .catch(err => message.warning("수정 실패"))
  }

  render() {
    const { productInfo } = this.state

    return (
      <div className={styles.modalwrapper}>
        <div className={styles.modalcontents}>
          <h4 className={styles.title}>수정하기</h4>
          <p></p>

          <form name="form">
            <table className={styles.inputs_table}>
              <tbody>
                <tr>
                  <td title="임시 이름">
                    <div>임시 이름</div>
                    <input
                      type="text"
                      value={productInfo.temporaryName}
                      onChange={e => this.handleValueChange(e, "temporaryName")}
                    />
                  </td>
                  <td title="제품의 제조사. ex)롯데">
                    <div>제조사/프랜차이즈 이름</div>
                    <input
                      type="text"
                      value={productInfo.manufacturer}
                      onChange={e => this.handleValueChange(e, "manufacturer")}
                    />
                  </td>
                  <td title="제품의 유통처. 특정한 유통처에서 구입할 수 있는 경우. ex)GS25, CU, 세븐일레븐">
                    <div>유통처</div>
                    <input
                      type="text"
                      value={productInfo.distributor}
                      onChange={e => this.handleValueChange(e, "distributor")}
                    />
                  </td>
                  <td title="제품의 브랜드 라인. 예시)노브랜드 or 닥터유 등">
                    <div>브랜드 라인</div>
                    <input
                      type="text"
                      value={productInfo.brandLine}
                      onChange={e => this.handleValueChange(e, "brandLine")}
                    />
                  </td>
                  <td title="제품의 시리즈. 예시)허니버터칩">
                    <div>시리즈</div>
                    <input type="text" value={productInfo.series} onChange={e => this.handleValueChange(e, "series")} />
                  </td>
                  <td>
                    <div>기본 이름</div>
                    <input
                      type="text"
                      value={productInfo.nameKor}
                      onChange={e => this.handleValueChange(e, "nameKor")}
                    />
                  </td>
                  <td title="제품 이름을 다르게 표기하는 경우">
                    <div>추가 이름</div>
                    <input
                      type="text"
                      value={productInfo.nameEtc}
                      onChange={e => this.handleValueChange(e, "nameEtc")}
                    />
                  </td>
                </tr>

                <tr>
                  <td title="과자 이름에 들어있는 맛 이름. ex)'허니버터칩 슈크림메이플맛'인 경우 '슈크림메이플맛'">
                    <div>맛 이름</div>
                    <input type="text" value={productInfo.flavor} onChange={e => this.handleValueChange(e, "flavor")} />
                  </td>
                  <td>
                    <div>가격</div>
                    <input type="text" value={productInfo.price} onChange={e => this.handleValueChange(e, "price")} />
                  </td>
                  <td>
                    <div>출시일/프로모션 시작일</div>
                    <input
                      type="date"
                      max="2999-12-31"
                      value={productInfo.promotionStartingDate}
                      onChange={e => this.handleValueChange(e, "promotionStartingDate")}
                    />
                  </td>
                  <td>
                    <div>단종일/프로모션 종료일</div>
                    <input
                      type="date"
                      max="2999-12-31"
                      value={productInfo.promotionEndingDate}
                      onChange={e => this.handleValueChange(e, "promotionEndingDate")}
                    />
                  </td>
                  <td title="단종 여부. 체크 되어있으면 단종 / 체크 되어 있지 않으면 판매 중">
                    <div>단종 여부</div>
                    <input type="checkbox" checked={productInfo.unavailable} onChange={e => this.handleValueCheck(e)} />
                  </td>
                </tr>
                <tr>
                  <td title="제품 유형">
                    <div>제품 유형</div>
                    <select value={productInfo.type} onChange={e => this.handleValueChange(e, "type")}>
                      <option value=""></option>
                      <option value="snack">과자</option>
                      <option value="chocolate">초콜릿</option>
                      <option value="drink">음료</option>
                      <option value="icecream">아이스크림</option>
                      <option value="candy">사탕</option>
                      <option value="jelly">젤리</option>
                      <option value="gum">껌</option>
                      <option value="bread">빵</option>
                      <option value="others">기타</option>
                    </select>
                  </td>

                  <td>
                    <div title="제품 주요 성분. ex)'꼬깔콘 새우마요맛'인 경우 '새우'">제품 주요 성분</div>
                    <input
                      type="text"
                      value={productInfo.basicIngredient}
                      onChange={e => this.handleValueChange(e, "basicIngredient")}
                    />
                  </td>
                  <td>
                    <div>제품 추가 성분</div>
                    <input
                      type="text"
                      value={productInfo.additionalIngredient}
                      onChange={e => this.handleValueChange(e, "additionalIngredient")}
                    />
                  </td>
                  <td>
                    <div>무게/용량</div>
                    <input type="text" value={productInfo.weight} onChange={e => this.handleValueChange(e, "weight")} />
                  </td>
                  <td>
                    <div>칼로리</div>
                    <input
                      type="text"
                      value={productInfo.calorie}
                      onChange={e => this.handleValueChange(e, "calorie")}
                    />
                  </td>
                  <td>
                    <div>바코드 번호</div>
                    <input
                      type="text"
                      value={productInfo.barcode}
                      onChange={e => this.handleValueChange(e, "barcode")}
                    />
                  </td>
                  <td>
                    <div>마케팅 문구</div>
                    <input
                      type="text"
                      value={productInfo.marketingPhrase}
                      onChange={e => this.handleValueChange(e, "marketingPhrase")}
                    />
                  </td>
                </tr>
                <tr>
                  <td colSpan="2">
                    <div>메모</div>
                    <input
                      type="text"
                      style={{ width: "100%" }}
                      value={productInfo.memo}
                      onChange={e => this.handleValueChange(e, "memo")}
                    />
                  </td>

                  <td colSpan="2">
                    <div>이미지 파일 선택</div>
                    <input
                      type="file"
                      accept="image/*"
                      ref={this.image_front_Ref}
                      id="imageFile"
                      onChange={this.handleFileChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>

        <div>
          <AButton
            type="primary"
            style={{ marginRight: "10px" }}
            onClick={() => {
              this.modify(this.state.productInfo.productID, this.state.productInfo)
            }}
          >
            수정하기
          </AButton>
          <AButton onClick={this.props.closeModal}>닫기</AButton>
        </div>
      </div>
    )
  }
}

export default Modify
