import React from "react"

import { Descriptions } from "antd"

import styles from "../../styles/Additional.module.scss"

const ProductInfoDisplay = ({ productInfo }) => {
  if (productInfo) {
    return (
      <div className={styles.ProductInfoDisplay}>
        <Descriptions bordered title={<div className={styles.title}>제품 정보</div>} size="small">
          <Descriptions.Item label="manufacturer">{productInfo.manufacturer}</Descriptions.Item>
          <Descriptions.Item label="brandLine">{productInfo.brandLine}</Descriptions.Item>
          <Descriptions.Item label="series">{productInfo.series}</Descriptions.Item>
          <Descriptions.Item label="nameKor">{productInfo.nameKor}</Descriptions.Item>
          <Descriptions.Item label="image">
            <img src={productInfo.image_front_300} style={{ maxWidth: 200, maxHeight: 200 }} alt="" />
          </Descriptions.Item>
        </Descriptions>
      </div>
    )
  } else {
    return null
  }
}

export default ProductInfoDisplay
