import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"

import styles from "../styles/Main.module.scss"

import AppendToDB from "../components/AppendToDB"
import ImageResize from "../components/AppendToDB/ImageResize"
import TestPage from "../components/TestPage/TestPage"
import SideMenu from "../components/SideMenu/SideMenu"
import AdditionalInfo from "../components/AdditionalInfo/AdditionalInfo"
import TagManagement from "../components/TagManagement/TagManagement"
import Search from "../components/Search/Search"

export class SPRouter extends React.Component {
  render() {
    return (
      <>
        <Router>
          <nav className={styles.sidemenu_wrapper}>
            <SideMenu />
          </nav>
          <section className={styles.contents_wrapper}>
            <Switch>
              <Route exact path="/">
                <AppendToDB />
              </Route>
              <Route path="/resize">
                <ImageResize />
              </Route>
              <Route path="/test">
                <TestPage />
              </Route>
              <Route path="/additional">
                <AdditionalInfo />
              </Route>
              <Route path="/tagManagement">
                <TagManagement />
              </Route>
              <Route path="/search">
                <Search />
              </Route>
            </Switch>
          </section>
        </Router>
      </>
    )
  }
}

export default SPRouter
