export function dragAndDropHandler(event) {
  document.addEventListener("dragenter", onDragEnterHandler, false)
  document.addEventListener("dragover", onDragOverHandler, false)
}

function onDragEnterHandler(e) {
  e.stopPropagation()
  e.preventDefault()
}

function onDragOverHandler(e) {
  e.stopPropagation()
  e.preventDefault()
}

function onDropHandler(e) {
  e.stopPropagation()
  e.preventDefault()

  const files = e.dataTransfer.files
  window.document.inputFiles = files
}
