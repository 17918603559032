import React, { useState } from "react"

import styles from "../../styles/Additional.module.scss"
import { Select, message, Tag, List, Button } from "antd"

import api from "../../api/index"

const SetProductTag = ({ productID, tags }) => {
  const [tagList, setTagList] = useState([])
  const [selectedTag, setSelectedTag] = useState()

  const getTagList = open => {
    if (open)
      api.getTagList().then(res => {
        setTagList(res.data)
      })
  }

  const handleTagSelect = value => {
    setSelectedTag(value)
    alert(value)
  }

  const handleRegisterTag = () => {
    if (productID && selectedTag) {
      api
        .registerTagToProduct(productID, selectedTag)
        .then(() => {
          message.success("등록에 성공했습니다.")
        })
        .catch(() => {
          message.warning("등록에 실패했습니다.")
        })
    } else {
      message.warning("제품 정보나 태그를 불러올 수 없습니다.")
    }
  }

  return (
    <div className={styles.SetProductTag}>
      <List
        size="large"
        header={<h5>제품 태그</h5>}
        bordered
        dataSource={tags}
        renderItem={item => (
          <List.Item>
            {item.tagName}
            <span className={styles.tag_wrapper}>
              <Tag color="volcano" className={styles.tagclass}>
                삭제
              </Tag>
            </span>
          </List.Item>
        )}
      />

      <br />
      <Select defaultActiveFirstOption={false} value={selectedTag} className={styles.selector} onChange={handleTagSelect} onDropdownVisibleChange={getTagList}>
        {tagList.map((item, index) => (
          <Select.Option value={item.tagID} key={index}>
            <Tag color="volcano">{item.tagName}</Tag>
          </Select.Option>
        ))}
      </Select>

      <Button type="primary" onClick={handleRegisterTag}>
        태그 등록
      </Button>
      {/* <br />
      <Input.Search placeholder="태그 입력" enterButton="태그 추가" size="default" onSearch={value => this.getAdditionalInfo(value)} /> */}
    </div>
  )
}

export default SetProductTag
