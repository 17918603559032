import { productType } from "../constants/productInfo"

export function parsePastedValue(str) {
  const newStr = str.split("	")

  for (let i = 0; i < newStr.length; i++) {
    newStr[i] = newStr[i].trim().replace(new RegExp("　", "g"), " ")
  }

  try {
    const result = {}

    result.temporaryName = newStr[4] === "(해당표기없음)" ? "" : newStr[4]
    result.manufacturer =
      newStr[9] === "(해당표기없음)" ? "" : newStr[9].indexOf("(주)") > -1 ? newStr[9].replace("(주)", "") : newStr[9]
    result.brandLine = newStr[7] === "(해당표기없음)" ? "" : newStr[7]
    result.nameKor = newStr[4] === "(해당표기없음)" ? "" : newStr[4]
    result.nameEtc = newStr[5] === "(해당표기없음)" ? "" : newStr[5]
    result.weight = newStr[16] === "(해당표기없음)" ? "" : newStr[16]
    result.barcode = newStr[1] === "(해당표기없음)" ? "" : newStr[1]
    result.image_front = newStr[23] === "(해당표기없음)" ? "" : newStr[23]

    result.previewKeyword = newStr[4] === "(해당표기없음)" ? "" : newStr[4]

    return result
  } catch (error) {
    return {}
  }
}

export function parsePastedValueShort(str) {
  const newStr = str.split("	")

  for (let i = 0; i < newStr.length; i++) {
    newStr[i] = newStr[i].trim().replace(new RegExp("　", "g"), " ")
  }

  try {
    const result = {}

    result.temporaryName = newStr[0]
    result.manufacturer = newStr[1]
    result.distributor = "CU"

    // 중량 분리
    const gramReg = /(\d+(g|ml))$/g

    if (newStr[2].search(gramReg) >= 0) {
      result.nameKor = newStr[2].substring(0, newStr[2].search(gramReg))
      result.weight = newStr[2].substring(newStr[2].search(gramReg))
    } else {
      result.nameKor = newStr[2]
    }

    result.price = newStr[3].replace(new RegExp(",", "g"), "")

    result.image_front = newStr[4]

    result.previewKeyword = newStr[2]

    return result
  } catch (error) {
    return {}
  }
}

export function parsePastedValueGoogleSheet(str) {
  const newStr = str.split("	")

  try {
    const result = {}

    result.temporaryName = newStr[1]
    result.manufacturer = newStr[2]
    result.distributor = newStr[3]
    result.brandLine = newStr[4]
    result.series = newStr[5]
    result.nameKor = newStr[6]
    result.nameEtc = newStr[7]
    result.price = newStr[8]
    result.type = productType[newStr[9]]
    result.flavor = newStr[10]
    result.basicIngredient = newStr[11]
    result.calorie = newStr[12]
    result.weight = newStr[13]

    if (newStr[14].length < 8) result.promotionStartingDate = newStr[14].replace(".", "-") + "-01"
    else result.promotionStartingDate = newStr[14].replace(".", "-")

    return result
  } catch (error) {
    console.log(error)
  }
}
