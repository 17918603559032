import React, { Component } from "react"

import { message, Button as AButton, Table as ATable, Pagination as APagination } from "antd"
import styles from "../../styles/Main.module.scss"

import { deleteFromS3 } from "../../util/aws"
import api from "../../api/index"

import Modify from "./Modify"

export class DBTable extends Component {
  state = {
    maxDisplay: 100,
    displayAtOnePage: 20,

    pageCount: 1,
    header: [],
    nheader: [],
    results: [],
    entireProductCount: 0,

    showModal: false,
    modifyingProductID: undefined,
    modifyProductInfo: undefined,
  }

  loadEntireList = (startNum, numOfItem) => {
    api.getEntireList(startNum, numOfItem).then(res => {
      const { entireProductCount } = res.data[0]

      const resultArr = []
      res.data.slice(1).forEach(item => {
        resultArr.push({
          deleteItem: (
            <>
              <span
                onClick={() => {
                  this.deleteProduct(item.productID)
                }}
                style={{ marginRight: 10 }}
              >
                삭제
              </span>
              <span
                onClick={() => {
                  this.openModal(item.productID, item)
                }}
              >
                수정
              </span>
            </>
          ),
          ...item,
        })
      })

      let header = Object.keys(resultArr[0]).map((item, index) => ({
        ellipsis: true,
        title: item,
        dataIndex: item,
        key: item,
      }))

      this.setState({
        entireProductCount, //entire Product Count
        pageCount: Math.ceil(this.state.maxDisplay / this.state.displayAtOnePage),
        results: resultArr,
        header,
      })
    })
  }

  clearEntireList = () => {
    this.setState({ results: [] })
  }

  deleteProduct = productID => {
    const response = window.confirm("정말 삭제하시겠습니까?")

    if (!response) return

    api
      .deleteProduct(productID)
      .then(() => deleteFromS3(productID))
      .then(() => {
        message.success("삭제 완료")
        this.loadEntireList(0, this.state.displayAtOnePage)
      })
      .catch(() => message.warning("삭제 실패"))
  }

  openModal = (modifyingProductID, modifyProductInfo) => {
    this.setState({ showModal: true, modifyingProductID, modifyProductInfo })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { header, results, displayAtOnePage, maxDisplay } = this.state

    return (
      <>
        <div className={styles.load_btns}>
          <AButton
            id="loadList"
            type="primary"
            onClick={() => {
              this.loadEntireList(0, this.state.displayAtOnePage)
            }}
            style={{ marginRight: 10 }}
          >
            최근 등록 리스트 불러오기
          </AButton>

          <AButton onClick={this.clearEntireList}>리스트 접기</AButton>
        </div>

        {!!this.state.entireProductCount && (
          <center>
            <br />총 제품 개수 : {this.state.entireProductCount}개<br />
            <br />
          </center>
        )}

        {!!this.state.results.length && (
          <ATable
            rowKey="productID"
            pagination={false}
            bordered={true}
            size="small"
            columns={header}
            dataSource={results}
            scroll={{ x: true }}
          />
        )}

        <br />
        <br />
        {!!this.state.results.length && (
          <div style={styles.btns}>
            <APagination
              defaultCurrent={1}
              defaultPageSize={displayAtOnePage}
              total={maxDisplay}
              size="large"
              onChange={(page, pageSize) => {
                this.loadEntireList((page - 1) * this.state.displayAtOnePage, this.state.displayAtOnePage, page)
              }}
              className={styles.pagination_wrapper}
            />
          </div>
        )}

        {this.state.showModal && (
          <Modify
            closeModal={this.closeModal}
            productID={this.state.modifyingProductID}
            productInfo={this.state.modifyProductInfo}
          />
        )}
      </>
    )
  }
}

export default DBTable
