import AWS from "aws-sdk"

var bucketName = "img.snackpot.kr"
var bucketRegion = "ap-northeast-2"
var IdentityPoolId = "ap-northeast-2:ceee66bd-c727-4c62-8126-e078c38c8526"

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: IdentityPoolId,
  }),
})

export const s3 = new AWS.S3({
  apiVersion: "2006-03-01",
  params: { Bucket: bucketName },
})

export default s3
