import React, { useState, useEffect } from "react"

import { Input, List, Tag, message } from "antd"

import api from "../../api/index"

import styles from "../../styles/TagManagement.module.scss"

const TagList = () => {
  const [tagList, setTagList] = useState([])

  useEffect(() => {
    api
      .getTagList()

      .then(res => {
        setTagList(res.data)
      })

      .catch(err => {
        message.warning("데이터를 불러올 수 없습니다. " + err)
      })
  }, [])

  const registerTag = value => {
    if (!value) {
      message.warning("내용을 입력해주세요.")
    } else {
      setTagList([{ tagName: value }, ...tagList])

      api
        .registerTag(value)
        .then()
        .catch(err => {
          message.warning("데이터를 불러올 수 없습니다. " + err)
        })
    }

    setTagList([{ tagName: value }, ...tagList])
  }

  return (
    <>
      <List
        size="large"
        header={<span className={styles.section_title}>일반 태그</span>}
        bordered
        grid={{ gutter: 16, column: 3 }}
        dataSource={tagList}
        pagination={{
          defaultPageSize: 36,
        }}
        renderItem={item => (
          <List.Item className={styles.tag_item}>
            <Tag color="volcano" closable>
              {item.tagName}
            </Tag>
            {/* <span className={styles.tag_wrapper}>
              <Tag color="volcano" className={styles.tagclass} onClick={() => deleteProductIssue(item.issueID)}>
                삭제
              </Tag>
            </span> */}
          </List.Item>
        )}
      />

      <br />

      <Input.Search placeholder="태그 입력" enterButton="태그 추가" size="default" onSearch={value => registerTag(value)} />
    </>
  )
}

export default TagList
