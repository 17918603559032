import { apiURL } from "../constants/api"
import baseAPI from "./base"

const actualAPI = {
  getEntireList: (startNum = 0, numOfItem = 20) => baseAPI.get(apiURL.GET_ENTIRE_LIST(startNum, numOfItem)),
  searchProduct: (keyword, searchConfig) => baseAPI.get(apiURL.SEARCH_PRODUCT(keyword), {params: searchConfig}),
  registerProduct: productInfo => baseAPI.post(apiURL.REGISTER_PRODUCT, productInfo),
  deleteProduct: productID => baseAPI.delete(apiURL.DELETE_PRODUCT(productID)),
  modifyProduct: (productID, productInfo) => baseAPI.put(apiURL.MODIFY_PRODUCT(productID), { productInfo }),
  registerProductImage: (productID, image_front, image_front_300) => baseAPI.put(apiURL.REGISTER_PRODUCT_IMAGE(productID), { image_front, image_front_300 }),

  getProductInfo: productID => baseAPI.get(apiURL.GET_PRODUCT_INFO(productID)),
  getProductAdditionalInfo: productID => baseAPI.get(apiURL.GET_PRODUCT_ADDITIONAL_INFO(productID)),

  getProductIssue: productID => baseAPI.get(apiURL.GET_PRODUCT_ISSUE(productID)),
  registerProductIssue: (productID, issueText) => baseAPI.post(apiURL.REGISTER_PRODUCT_ISSUE(productID), { issueText }),
  deleteProductIssue: issueID => baseAPI.delete(apiURL.DELETE_PRODUCT_ISSUE(issueID)),

  getProductTag: productID => baseAPI.get(apiURL.GET_PRODUCT_TAG(productID)),
  getProductDiscountTag: productID => baseAPI.get(apiURL.GET_PRODUCT_DISCOUNT_TAG(productID)),

  getTagList: () => baseAPI.get(apiURL.GET_TAG_LIST),
  getDiscountTagList: () => baseAPI.get(apiURL.GET_DISCOUNT_TAG_LIST),

  registerTag: tagName => baseAPI.post(apiURL.REGISTER_TAG, { tagName }),
  registerDiscountTag: tagName => baseAPI.post(apiURL.REGISTER_DISCOUNT_TAG, { tagName }),

  registerTagToProduct: (productID, tagID) => baseAPI.post(apiURL.REGISTER_TAG_TO_PRODUCT(productID), { tagID }),
  registerDiscountTagToProduct: (productID, tagID) => baseAPI.post(apiURL.REGISTER_DISCOUNT_TAG_TO_PRODUCT(productID), { tagID }),
}

export default actualAPI
